// Here you can add other styles
@media (max-width: 991.98px) {
  .app-header {
    .navbar-brand {
      left: 20%;
    }
  }
}
.app-header {
  .nav-item {
    min-width: 40px;
  }
}

div[even="true"] {
  background-color: rgba(0, 0, 0, 0.05);
}

.reacttable {
  display: flexbox;
  border-spacing: 0;
  border: 1px solid lightgrey;
  div[color="red"] {
    background: rgb(255, 125, 125);
  }
  div[color="yellow"] {
    background: rgb(255, 255, 125);
  }
  div[color="green"] {
    background: rgb(125, 255, 255);
  }
  .tr {
    :last-child {
      .td {
        border-bottom: 1px solid lightgrey;
      }
    }
  }
  .filterdiv {
  position: relative;
  }
  .th,
  .td {
    margin: 0;
    padding: 0.2rem;
    border-bottom: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    :last-child {
      border-right: 0;
    }
    // .resizer {
    //   display: inline-block;
    //   background: gray;
    //   width: 10px;
    //   height: 100%;
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   transform: translateX(50%);
    //   z-index: 1;
    //   touch-action: none;
    //   &.isResizing {
    //     background: darkgray;
    //   }
    // }
  }
}
