
.login-image {
 
  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.btn-row{
    float: left; 
    margin-right: 10px;
}
.custom-map-modal .modal-dialog {
    max-width: 100%;
    max-height: 100%;  
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
.custom-map-modal .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }
  label {
    white-space: pre-wrap;
  }

  .table-fixed {
    width: 100%;
    background-color: #f3f3f3;
 }
  .table-fixed tbody {
    height: 500px;
    overflow-y: auto;
    width: 100%;
 }
  .table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
    display: block;
 }
  .table-fixed tbody td {
    float: left;
 }
  .table-fixed thead tr th {
    float: left;
    background-color: #f39c12;
    border-color: #e67e22;
 }
 

 .dtrg-level-0{
  background-color:lightgreen !important;
  font-weight: bold;

}
 .dtrg-level-1{
   background-color: lightskyblue !important;
   font-weight: bold;
  text-align: center;
 }
